export default class AppConfig {
	static environment = AppConfig.getEnvironment();
	static build = '#{Build.BuildNumber}# #{Release.EnvironmentName}#';
	static release = '#{Release.ReleaseName}#';
	static appPrefix = 'SurplusInventory ';

	static events = {
		controllerActivateSuccess: 'controller.activateSuccess',
		spinnerToggle: 'spinner.toggle',
		controllerTitleUpdate: 'controller.titleUpdate'
	};

	static keyCodes = {
		backspace: 8,
		tab: 9,
		enter: 13,
		esc: 27,
		space: 32,
		pageup: 33,
		pagedown: 34,
		end: 35,
		home: 36,
		left: 37,
		up: 38,
		right: 39,
		down: 40,
		insert: 45,
		del: 46
	};
	
	static remoteServiceName = 'controllers';

	static config = {
		appErrorPrefix: '[Surplus Inventory Error] ', // Configure the exceptionHandler decorator
		docTitle: 'Surplus Inventory: ',
		events: self.events,
		keyCodes: self.keyCodes,
		remoteServiceName: self.remoteServiceName
	}
	
	// AzureAD Config - Get these values from the AzureAD application registration created by the Identity Team
	static aadInstance = 'https://login.microsoftonline.com/';
	static aadTenant = 'conocophillips.onmicrosoft.com';
	static aadClientId = AppConfig.getClientId();

	static aadRedirectEndpoints = {
		"http://localhost/SurplusInventory.API/": "https://ConocoPhillips.onmicrosoft.com/b4a3fee1-f6c3-440a-b4a8-419f55b11a3a", // Comment out to force external site
		"https://surplusinventoryapi-t.ca.cloudcopapps.net/": "https://ConocoPhillips.onmicrosoft.com/b4a3fee1-f6c3-440a-b4a8-419f55b11a3a",
		"https://surplusinventoryapi-int-t.ca.cloudcopapps.net/": "https://ConocoPhillips.onmicrosoft.com/b4a3fee1-f6c3-440a-b4a8-419f55b11a3a",
		"https://surplusinventoryapi.ca.cloudcopapps.net/": "https://ConocoPhillips.onmicrosoft.com/8f5fadf1-a091-485a-8add-24f9449ed136",
		"https://surplusinventoryapi-int.ca.cloudcopapps.net/": "https://ConocoPhillips.onmicrosoft.com/8f5fadf1-a091-485a-8add-24f9449ed136"
	};

	constructor() { }

	static getEnvironment() {
		const environments = {
			'localhost:8080': 'Local',
			'surplusinventory-int-t.ca.cloudcopapps.net': 'Test',
			'surplusinventory-int.ca.cloudcopapps.net': 'Production'
		};
		return environments[location.host];
	}

	static getClientId() {
		const aadapplicationId = {
			'localhost:8080': 'd8f704f6-458f-499d-8fd5-ced5107173f3',
            'surplusinventory-int-t.ca.cloudcopapps.net': '002ea91b-8e2a-45c9-b76f-b3c8db6ab253',
            'surplusinventory-int.ca.cloudcopapps.net': 'dbeb2640-b868-47cb-989e-b02b00cdd28d'
		};
		return aadapplicationId[location.host];
	}
}